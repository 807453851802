import React, {useContext, useState} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import styles from './styles/CalendarBlock.module.scss';
import {StoreContext, modalStore} from "../../../../stores/StoreLoader";
import Calendar from "../../../utilities/CleverCalendar/calendar";
import WatsonApi from "../../../../backends/WatsonApi";
import useSWR from "swr";
import {deleteContentItem} from "../Terrace";
import {useMountedEffect} from "../../../../hooks/useMountedEffect";
import ViewMoreButton from "../ViewMoreButton";
import {ReactiveContentItem} from "../../../../stores/SchoolFeedStore";

const calendarContainerClassName = classNames({
    [styles.calendarContainer]: true,
});

const handleSelectEvent = (event, handleSave, handleDelete) => {
    modalStore.addModal({
        type: "events",
        id: 'events-modal',
        itemObj: event,
        handleDelete,
    });
};

const CalendarBlock = observer(props => {
    const [currentRange, setCurrentRange] = useState({
        start: moment().startOf('month').subtract(3, 'days'),
        end: moment().endOf('month').add(3, 'days'),
    });
    const {modalStore, organizationStore} = useContext(StoreContext);

    const handleRangeChange = async () => {
        //    Callback fired when the visible date range changes. Returns an Array of dates or an object with start and end dates for BUILTIN views.
        let gte, lt;
        if (Array.isArray(currentRange)) {
            gte = moment(currentRange[0]).format('YYYY-MM-DD');
            lt = moment(currentRange[currentRange.length - 1]).format('YYYY-MM-DD');
        } else {
            gte = currentRange.start.format('YYYY-MM-DD');
            lt = moment(currentRange.end).format('YYYY-MM-DD');
        }

        const client = await WatsonApi();
        const obj = await client.apis.organizations.organizations_content_items_list({
            'organization_pk': props.blockObj.organization_id,
            'is_event': true,
            'created_at__gte': gte,
            'created_at__lt': lt,
            'page_size': 200,
            'published': true,
            'expand': ['organization'],
        });
        return JSON.parse(obj.data).data.map(d => new ReactiveContentItem(d));
    };

    function useGetEvents (currentRange) {
        const {data: events, isLoading, mutate} = useSWR(JSON.stringify(currentRange), handleRangeChange, {
            revalidateIfStale: false,
            revalidateOnFocus: false,
        });

        return {
            events,
            isLoading,
            mutate,
        }
    }

    const {events, isLoading, mutate} = useGetEvents(currentRange);

    useMountedEffect(() => {
        // update when new events are added
        mutate();
    }, [props.blockObj.content_items.length])

    const handleDelete = async (contentItem) => {
        await deleteContentItem(contentItem);
        modalStore.clearAllModals();
        mutate();
    }

    return (
        <div className={calendarContainerClassName}>

            <Calendar
                events={events || []}
                loading={isLoading}
                onRangeChange={setCurrentRange}
                onEventClick={handleSelectEvent}
                aboveTheFold={props.aboveTheFold}
                expandedView={false}
                handleDeleteEvent={handleDelete}
                viewMoreButton={<ViewMoreButton text={"View More Events"}
                                                terraceType={props.blockObj.type}
                                                organizationId={organizationStore.currentOrganization.id}
                                                className={styles.viewMoreButton}/>}
            />



        </div>
    )
})

export default CalendarBlock;
