import classNames from "classnames";
import styles from "./styles/EventsBlock.module.scss";

import CalendarBlock from './CalendarBlock';
import BlockTitle from "../_BlockTitle";
import React, {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {SupportedContentItemTypes} from "../../../schoolfeed/PostManager/SchoolFeedPostManager";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import {useCreateContentItem} from "../../../../hooks/useCreateContentItem";

const EventsBlock = (props: {
    blockObj: IEventTerrace,
    aboveTheFold: boolean,
}) => {
    const {sidebarStore} = useContext(StoreContext);

    const {handleSave} = useCreateContentItem()

    const eventsBlockWrapperClassName = classNames({
        [styles.eventsBlockWrapper]: true,
    });

    const onClickEvent = () => {
        sidebarStore.setSidebar(
            {
                view: "SchoolFeedPost",
                sbtype: SupportedContentItemTypes.EVENT,
                handleSave: handleSave,
            }
        )
    }

    const onClickCalendar = () => {
        sidebarStore.setSidebar(
            {
                view: "AddSocial",
                sbtype: 'ICAL',
            }
        )
    }

    const eventsBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Event',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClickEvent,
        },
        {
            title: 'Add Calendar',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClickCalendar,
        },
    ]

    return (
        <section className={eventsBlockWrapperClassName}>
            <BlockTitle blockObj={props.blockObj} aboveTheFold={props.aboveTheFold} dropDownItems={eventsBlockDropDownItems}/>
            <CalendarBlock blockObj={props.blockObj} aboveTheFold={props.aboveTheFold} />
        </section>
    )
};

export default EventsBlock;
